footer{
    background-color: var(--blk-color);
    padding: 4rem 10px 0 10px;
    border-top: 3px solid #fff;
}
footer * {
    color: #fff;
}
footer .logo-footer img{
    padding-bottom: .5rem;
}
footer .f-title{
    font-weight: bold;
    position: relative;
    padding-bottom: 1rem;
}
footer .f-title::after{
    content: '';
    display: block;
    width: 50px;
    height: 1px;
    background-color: var(--p-color);
    position: absolute;
    left: 0;
    bottom: 10px;
}
footer .social-footer{
    padding-top: 1rem;
}

footer .cpr{
    text-align: center;
    font-size: .75rem;

    margin-top: 4rem;
    padding: .5rem;
    border-top: 1px solid #fff;
}

@media screen and (max-width: 767px) {
    /* footer{
        padding-bottom: 70px;
    } */
    footer .f-title{
        margin-top: 2rem;
    }
}
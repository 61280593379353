.products-admin .option {
    min-width: max-content;
}

.products-admin .dropdown {
    z-index: 99;
}

.products-admin .option .dropdown {
    right: auto;
    left: 0;
}

.products-admin table img {
    width: 70px;
    height: 70px;
    border-radius: 5px;
}

.products-admin .table table td:last-of-type>div .dropdown {
    top: calc(1rem + 10px);
    bottom: auto;
}

@media screen and (max-width: 767px) {
    .products-admin .table table {
        min-width: max-content;
    }
}
.banner {
    width: 100%;
    overflow: hidden;
}

.banner .container {
    transition: all .3s ease;
}

.banner .container .content {
    min-width: 100%;
    position: relative;
}

.banner .container .content .text {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
}

.banner .container .content .text span,
.banner .container .content .text p {
    color: white;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

.banner .container .content .text span {
    font-size: .85rem;
}

.banner .container .content .text p {
    max-width: 65%;
    font-size: 2.2rem;
    font-weight: bold;
    color: white;
    line-height: 3rem;
}

.banner .content button {
    font-weight: bold;
    margin-top: 1rem;
    width: 130px;
}

.banner .content img {
    width: 100%;
}
.banner-controller {
    padding: 1rem 0;
}
.banner-controller .prev-btn {
    background-color: var(--light-gray-color);
}

.banner-controller button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    transition: transform .3s ease;
}

.banner-controller button svg {
    color: var(--gray-color);
    font-size: 1.2rem;
}

.banner-controller button.prev-btn {
    background-color: var(--light-gray-color);
}

.banner-controller button.next-btn {
    background-color: var(--p-color);
}

.banner-controller button.next-btn svg {
    color: #fff;
}

.banner-controller button:hover {
    transform: scale(.95);
}

@media screen and (max-width: 767px) {
    .banner {
        height: 100vw;
    }

    .banner .container {
        margin-top: 1rem;
        height: 100vw;
    }

    .banner .content img {
        height: 100vw;
        object-fit: cover;
        object-position: 70% 0;
    }

    .banner-controller button {
        width: 35px;
        height: 35px;
    }

    .banner .container .content .text {
        left: 5%;
        width: 90%;
        top: auto;
        bottom: 0;
    }

    .banner .container .content .text span {
        font-size: .75rem;
    }

    .banner .container .content .text p {
        font-size: 1.5rem;
        max-width: 80%;
        line-height: 1.8rem;
    }

    .banner .content button {
        font-size: 12px;
        width: 100px;
        height: 30px;

        margin-top: .5rem;
    }

    .banner-controller {
        padding: 5px 10px;
        box-sizing: border-box;
    }
}
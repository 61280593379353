/* .user p,
.user a,
.user span,
.user h2,
.user h1,
.user h3,
.user label,
.user .cart table th {
    color: #fff;
} */

input,
textarea {
    border: none;
    background-color: transparent;
    padding: 0 .35rem;
}

.input,
textarea {
    background-color: var(--a-bg-color);
    padding: .45rem .75rem;
    box-sizing: border-box;
    border-radius: 5px;
    border: var(--a-border-color);
}

input::placeholder,
textarea::placeholder {
    color: #CFD5E1;
}

textarea {
    resize: none;
}

textarea:focus {
    outline: none;
}

main.user {
    width: 100%;
    max-width: 1200px;
    padding-bottom: 15vh;
    display: grid;
    grid-template-columns: 300px auto;
    gap: 25px;

    margin: 0 auto;

    box-sizing: border-box;
}

.s-m {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    padding: .5rem .75rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;

    min-width: 85px;
    transition: all .3s ease;
    color: white;
    background-color: var(--p-color);
}

.primary-button:hover {
    background-color: var(--blk-color);
    transform: scale(.95);
    opacity: .9;
}

.u-title {
    font-size: 1rem;
    font-weight: bold;
    /* color: var(--blk-color); */
    padding-bottom: .35rem;
    border-bottom: 1px solid var(--border-color);
}

span.new,
.onSale {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;

    position: absolute;
    top: 10px;
    right: 10px;

    border-radius: 50%;
    font-size: .75rem;
    color: #fff;

    background-color: var(--red-color);

    z-index: 1;
}

span.new {
    background-color: #46C389;
}

button.add-to-cart {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    background-color: var(--p-color);
    padding: .5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    margin-bottom: 1rem;

    transition: transform .3s ease;
}

button.add-to-cart:hover {
    background-color: var(--blk-color);
    transform: scale(.95);
}

.u-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 995;
}

@media screen and (max-width: 767px) {
    main.user {
        grid-template-columns: 1fr;
        padding: 10px 10px 10vh 10px;
    }

    main.user * {
        box-sizing: border-box;
    }
}
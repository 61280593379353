.login-admin {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #f5f5fa;
}

.login-admin .container {
    width: 400px;
    max-width: 100%;
    height: max-content;
    padding: 2rem;
    border-radius: 5px;
    background-color: white;
    text-align: center;

    box-shadow: 0 5px 25px -15px rgba(0, 0, 0, .3);

    box-sizing: border-box;
}

.login-admin input {
    display: block;
    border: 1px solid var(--a-border-color);
    margin-bottom: .65rem;
}

.login-admin input,
.login-admin button {
    width: 100%;
    border-radius: 5px;
    padding: .5rem;
}

.login-admin button{
    background-color: var(--a-p-color);
    color: #fff;
    border: none;
    margin-top: 1rem;

    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .login-admin .container {
        width: 100%;
        height: 100vh;

        padding-top: 10vh;
    }
}
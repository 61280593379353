.chart {
    width: 100%;
    max-width: calc(100vw - 20px);
}

@media screen and (max-width: 767px) {
    .dashboard-admin .table table {
        width: max-content;
    }

    .dashboard-admin .table table th:last-of-type,
    .dashboard-admin .table table td:last-of-type {
        display: none;
    }
}
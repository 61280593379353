.product-single img {
    aspect-ratio: 1/1;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.product-single .thumbnail {
    aspect-ratio: 1/1;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.product-single button {
    width: 35px;
    height: 35px;
    border: none;

    cursor: pointer;
}

.product-single .thumbnail button:hover svg {
    color: #fff;
}

.product-single .controller-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.25);
}

.product-single .controller-btn.prev {
    left: 10px;
}

.product-single .controller-btn.next {
    right: 10px;
}

.product-single .detail .quantity span {
    padding: 1rem 1.5rem;
    user-select: none;
}

.product-single .detail .quantity button {
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
}

.product-single .detail .quantity button svg {
    color: white;
}

.product-single .detail .quantity button.minus {
    background-color: #46C389;
}

.product-single .detail .quantity button.plus {
    background-color: var(--red-color);
}

.product-single .detail .quantity button:hover,
.product-single button:hover {
    background-color: var(--blk-color);
}


.product-single .detail .name {
    font-size: 1.35rem;
    font-weight: 600;
}


.product-single .detail .price {
    display: flex;

    padding: 1rem 0 1.25rem 0;
    border-bottom: 1px solid var(--border-color);
}

.product-single .detail .newPrice {
    font-size: 1.35rem;
    font-weight: 700;
}

.product-single .detail .beforeSale {
    font-size: 1.35rem;
    color: var(--gray-color);
    font-weight: 400;
    text-decoration: line-through;
}

.product-single .detail .u-title {
    font-weight: 600;
    border-bottom: none;
    color: var(--p-color);
    padding: 1.2rem 0 .5rem 0;
}

.product-single .detail .desc {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.product-single .detail .desc * {
    font-size: .85rem;
    font-weight: 400;
    /* color: var(--gray-color); */
}

.product-single .add-to-cart {
    width: 180px;
    height: auto;
}


/* other products */
.other-product {
    border-top: 1px solid var(--border-color);

    padding-top: 2rem;
}

.other-product .heading {
    text-align: center;
    position: relative;

    padding-bottom: .5rem;
    margin-bottom: 1.5rem;
}

.other-product .heading::before {
    content: '';
    display: block;
    width: 80px;
    height: 3px;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    background-color: var(--p-color);
}


@media screen and (max-width: 767px) {
    .product-single .add-to-cart {
        width: 100%;
    }
}
.sidebarMenuMobile {
    position: fixed;
    top: 0;
    left: -100%;
    min-height: 100vh;
    width: 350px;
    max-width: 80vw;
    background-color: white;
    box-sizing: border-box;
    padding: 2rem;
    z-index: 999;
    transition: left .5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sidebarMenuMobile.show {
    left: 0;
    transition: left .5s ease;

    overflow-y: scroll;
}

.sidebarMenuMobile .u-title{
    color: var(--p-color);
}

.sidebarMenuMobile .list a{
    color: var(--gray-color);
    padding: .5rem 0;
    display: block;
    border-bottom: 1px solid var(--border-color);
}

.sidebarMenuMobile .list a.active{
    font-weight: 500;
    color: var(--blk-color);
}

.sidebarMenuMobile .sidebar-container{
    overflow-y: scroll;
    max-height: 85vh;
}
.sidebarMenuMobile .sidebar-container::-webkit-scrollbar {
    width: 0;
  }
.user-detail-admin .container {

    padding: 2rem;
    border: 1px solid var(--a-border-color);
    border-radius: 10px;
    background-color: #fff;
}

.user-detail-admin .container .header {
    margin-bottom: 2rem;
}

.user-detail-admin .date {
    font-size: 14px;
    margin: 0 0 5px 0 ;
}

.user-detail-admin .container button {
    background-color: #fff;
    border: 1px solid var(--a-border-color);
    height: 35px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: .5rem;
    cursor: pointer;
}

.user-detail-admin .container button svg {
    color: var(--a-p-color);
    font-size: 1.2rem;
}

.user-detail-admin .container .icon{
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    background-color: var(--a-bg-color);
    margin-right: 1rem;
}
.user-detail-admin .container .icon svg{
    font-size: 2.5rem;
    color: var(--a-blk-color);
}
.user-detail-admin .container .detail-content .title{
    margin-bottom: 5px;
}

.user-detail-admin .table {
    padding-bottom: 10px !important;
}


.user-detail-admin .table .image {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 1rem;
}

.user-detail-admin .table .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .user-detail-admin .container .header br{
        display: none;
    }
}
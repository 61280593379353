.pagination ul {
    flex-wrap: wrap;
}

.pagination li {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    color: var(--a-blk-color);
    width: 35px;
    height: 35px;
    border-radius: 5px;

    cursor: pointer;
}

.pagination li.active {
    color: #fff;
    background-color: var(--a-p-color);
    cursor: auto;
}

@media screen and (max-width: 767px) {
    .pagination {
        display: flex;
        justify-content: center;
    }
}
.add-product .container {
    background-color: #fff;
    border-radius: 10px;

    padding: 3rem 2rem;
    max-height: 80vh;
}

.add-product .container div {
    box-sizing: border-box;
    width: 100%;
}

.add-product .title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: .5rem;
    color: var(--a-blk-color);
}

.add-product .title span {
    color: var(--a-red-color);
}

.add-product textarea,
.add-product input {
    background-color: var(--a-bg-color);
    padding: .5rem 1rem;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    margin-bottom: 2rem;
}

.add-product .option {
    width: 100%;
    box-shadow: none;
}

.add-product .thumbnail,
.add-product .galleries {
    width: 100%;
    min-height: 260px;

    background-color: var(--a-bg-color);
    border-radius: 10px;

    margin-bottom: 25px;

    overflow: hidden;
    cursor: pointer;
}

.add-product .thumbnail svg,
.add-product .galleries svg {
    font-size: 5rem;
    color: var(--a-p-color);
}

.add-product .thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.add-product .upload-galleries .list {
    border-radius: 5px;
    border: 1px solid var(--a-border-color);
    padding: 10px;
    margin-bottom: .5rem;
}

.add-product .upload-galleries img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
}

.add-product .upload-galleries .icon {
    background-color: var(--a-light-red-color);
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .3s ease;
}

.add-product .upload-galleries .icon:hover {
    transform: scale(.95);
}

.add-product .upload-galleries svg {
    color: var(--a-red-color);
}


@media screen and (max-width: 767px) {
    .add-product .container{
        padding: 2rem 1rem;
    }
}
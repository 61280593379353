.form-contact{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    border: 1px solid var(--border-color);
    border-left: none;
}

.form-contact input,.form-contact textarea,.form-contact button{
    width: 100%;
    box-sizing: border-box;

    margin-bottom: .75rem;
}

.form-contact button{
    margin-top: 2rem;
}
.flashDeal{
    max-height: 400px;
    border-radius: 10px;
}
.flashDeal > div{
    border-radius: 10px;
    overflow: hidden;
}
.flashDeal > div:nth-child(odd) > div:last-child{
    background-color: #F5EAEE;
}
.flashDeal > div:nth-child(even) > div:last-child{
    background-color: #EEEEF6;
}
.flashDeal .thumbnail{
    position: relative;
    box-sizing: border-box;
    padding: 1rem;
    border: 1px solid var(--border-color);
    border-right: none;
    border-radius: 10px 0 0 10px;
}

.flashDeal .thumbnail .onSale{
    background-color: var(--p-color);
    width: 50px;
    height: 50px;
    top: 1.35rem;
    right: 1.35rem;
}
.flashDeal .thumbnail img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    object-fit: cover;
}

.flashDeal .tag-tile{
    color: var(--p-color);
    font-size: .75rem;
    font-weight: bold;
}
.flashDeal .name{
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--blk-color);
    line-height: 1.8rem;
    margin: 1rem 0;
}

.flashDeal button.add-to-cart{
    font-size: .75rem;
}

@media screen and (max-width: 767px) {
    .flashDeal{
        width: 100%;
        max-height: max-content;
    }
}
header.user{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;
    position: relative;
    
    border-bottom: 1px solid var(--border-color);
    z-index: 0;
}

@media screen and (max-width: 767px) {
    header.user{
        padding-top: 55px;
    }
}
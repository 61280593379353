.completeOrder .primary-color,
.completeOrder .large-icon{
    font-weight: bold;
    color: var(--p-color);
}

.completeOrder .large-icon {
    font-size: 15vw;
}

@media screen and (max-width: 767px) {
    .completeOrder .large-icon {
        font-size: 40vw;
        margin: 1rem 0;
    } 
}
input,
textarea {
    border: none;
    background-color: transparent;
    padding: 0 .35rem;
}

.input,
textarea {
    background-color: var(--a-bg-color);
    padding: .45rem .75rem;
    box-sizing: border-box;
    border-radius: 5px;
    border: var(--a-border-color);
}

input::placeholder,
textarea::placeholder {
    color: #CFD5E1;
}

textarea {
    resize: none;
}

textarea:focus {
    outline: none;
}

p,
span,
svg {
    color: var(--a-blk-color);
}

.dropdown {
    position: absolute;
    top: 30px;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 1px 15px -10px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    min-width: 150px;
}

.dropdown ul {
    margin: .5rem 0;
}

.dropdown .link {
    font-size: 14px;
    padding: .5rem 1.2rem;
    cursor: pointer;
    user-select: none;
}

.dropdown .link.active,
.dropdown .link:hover {
    background-color: var(--a-hover-bg-color);
}

.dropdown .link.active {
    color: var(--a-p-color);
}

.dropdown a {
    display: block;
}

.primary-color {
    color: var(--a-p-color);
}

.red-color,
.red-color svg {
    color: var(--a-red-color);
}

.bg-primary {
    background-color: var(--a-bg-color);
}

.margin-bt-1 {
    margin-bottom: 1.5rem;
}

.heading {
    margin: 0;
}

.admin .primary-button,
.second-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    padding: .5rem .75rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.admin .primary-button {
    background-color: var(--a-p-color);
    color: white;
    min-width: 85px;
    transition: all .3s ease;
}

.second-button {
    background-color: var(--a-border-color);
    color: var(--a-blk-color);
}

.admin .primary-button svg {
    color: white;
}

.second-button svg {
    color: var(--a-blk-color);
}

.admin .primary-button:hover {
    transform: scale(.95);
    opacity: .9;
}

main.admin {
    display: grid;
    grid-template-columns: calc(1rem + 200px) calc(100% - calc(1rem + 200px));
}

main.admin .contents {
    min-height: calc(100vh - 90px - 25px);
    background-color: var(--a-bg-color);
    padding: 25px 10px 40px 25px;
    box-sizing: border-box;
}

.table {
    width: 100%;
    background-color: white;
    margin-top: 1.5rem;
}

.table table {
    width: 100%;
    border-collapse: collapse;
}

.table table th {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0;
    text-align: left;
    background-color: #F9F9FD;
    border: none;
}

.table table tr:not(:last-of-type) {
    border-bottom: 1px solid var(--a-border-color);
}

.table table td {
    padding: 1rem 0;
}

.table table th:first-of-type,
.table table td:first-of-type {
    padding-left: 1rem;
}

.table table img {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.status-complete,
.status-pending,
.status-cancel,
.status-draft {
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 20px;
}

.status-complete {
    color: var(--a-green-color);
    background-color: var(--a-light-green-color);
}

.status-pending {
    color: var(--a-orange-color);
    background-color: var(--a-light-orange-color);
}

.status-cancel {
    color: var(--a-red-color);
    background-color: var(--a-light-red-color);
}

.status-draft {
    color: var(--a-gray-color);
    background-color: var(--a-light-gray-color);
}

.table table .price {
    color: var(--a-green-color);
}

.box-content,
.table {
    background-color: white;
    padding: 1rem 0;
    border-radius: 10px;
    border: 1px solid var(--a-border-color);
}

.table .title,
.box-content .title {
    font-weight: 700;
    padding: 0 1rem .5rem 1rem;
    border-bottom: 1px solid var(--a-border-color);
}

.table .title {
    border-bottom: none;
}

.table table td:last-of-type svg {
    cursor: pointer;
}

.table table td:last-of-type>div {
    position: relative;
    display: inline-flex;
}

.table table td:last-of-type>div .dropdown {
    display: none;
    top: auto;
    bottom: calc(1rem + 10px);
    min-width: 150px;
    width: max-content;
    background-color: #fff;
}

.table table .actions svg {
    padding: 10px;
}

.table table tfoot {
    background-color: var(--a-bg-color);
}

.box-content .container-header {
    padding: 0 1rem .5rem 1rem;
    border-bottom: 1px solid var(--a-border-color);
}

.box-content .container-header .title {
    padding: 0;
    border-bottom: none;
}


.overlay {
    background-color: transparent;
    z-index: 99;
    width: 100vw;
    pointer-events: none;
    transition: background-color .3s linear;
}

.overlay.show {
    background-color: rgba(0, 0, 0, .7);
    pointer-events: all;
}

.option {
    font-size: 14px;
    width: 100px;
    padding: .5rem .5rem .5rem .75rem;
    border: 1px solid var(--a-border-color);
    box-shadow: 2px 2px 7px -5px rgba(40, 60, 76, 0.25);
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    background-color: #fff;
}

.list-per-page {
    width: 120px;
    background: #FFFFFF;
    border: 1px solid var(--a-border-color);
    box-shadow: 2px 2px 7px -5px rgba(40, 60, 76, 0.25);
    border-radius: 5px;
    padding: .5rem .5rem .5rem .75rem;
}

@media screen and (max-width: 767px) {
    main.admin {
        grid-template-columns: 1fr;
    }

    main.admin .contents {
        min-height: calc(100vh - 60px);
        padding: 1.2rem 9px;
    }

    .table {
        width: calc(100vw - 20px);
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .table table {
        width: 100%;
        min-width: max-content;
    }

    .table table td {
        padding: 1rem 1rem 1rem 0;
    }


}
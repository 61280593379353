.mobileNav {
    background: #fff;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 50px;
    /* height: 55px; */
    margin: auto;
    box-shadow: 0 0 10px -3px hsla(0, 0%, 0%, 0.25);
    z-index: 990;
}

.mobileNav button {
    border: none;
    background-color: transparent;
    position: relative;
}

.mobileNav button svg {
    font-size: 1.65rem;
    color: var(--blk-color);
}

.mobileNav button .count{
    color: #fff;
    font-size: 8px;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0px;
    right: 2px;
    background-color: var(--red-color);
}

nav.user .list{
    font-size: 0.9rem !important;
}


.mobileNav ul{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
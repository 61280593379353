.checkout table {
    width: 100%;
    border-collapse: collapse;
}

.checkout .account {
    width: 100%;
}

.checkout .account span {
    color: var(--red-color);
}

.checkout .right {
    min-width: 300px;
}

.checkout .account .top-title {
    padding: .5rem 0;
    border-bottom: 2px solid var(--border-color);
}

.checkout .second-title {
    font-size: .85rem;
    padding-bottom: .5rem;
    border-bottom: 2px solid var(--border-color);
    margin-bottom: 1rem;
}

.checkout .total {
    margin-top: .75rem;
    padding: .75rem 0 1rem 0;
    border-top: 1px dashed var(--border-color);
}

.checkout .right button {
    font-weight: bold;
    color: #fff;

    padding: .75rem;
    border-radius: 5px;
    width: 100%;
    border: none;
    background-color: var(--red-color);

    cursor: pointer;

    transition: all .3s ease;
}

.checkout .right button:hover {
    transform: scale(.95);
    background-color: var(--blk-color);
}

@media screen and (max-width: 767px) {
    .checkout .right {
        width: 100%;
    }
}
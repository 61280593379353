.stepCheckout {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin: 2rem auto 0 auto;
    position: relative;
}

.stepCheckout .step svg {
    color: #fff;
}

.stepCheckout .step,
.stepCheckout .circle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepCheckout .step {
    width: 70px;
    flex-direction: column;
}

.stepCheckout .circle {
    font-size: 12px;
    font-weight: bold;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--gray-color);
    margin: 10px 0;
}

.stepCheckout p {
    font-size: 12px;
    /* color: var(--green-color); */
}

.stepCheckout .step.active .circle {
    background-color: var(--p-color);
}

.stepCheckout .step>svg {
    color: var(--p-color);
}

.stepCheckout svg {
    font-size: 20px;
}

.stepCheckout .progress.active {
    position: absolute;
    top: 50%;
    left: 30px;
    width: 0%;
    height: 2px;
    background-color: var(--p-color);
    transform: translate(0, -50%);
    z-index: -1;
}

.stepCheckout .progress {
    position: absolute;
    top: 50%;
    left: 30px;
    width: calc(100% - 60px);
    height: 2px;
    background-color: var(--gray-color);
    transform: translate(0, -50%);
    z-index: -2;
}

@media screen and (max-width: 767px) {
    .stepCheckout {
        width: 280px;
    }
}
.CardProduct {
    margin-top: 1rem;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--border-color);
    transition: all .3s linear;
}

.CardProduct:hover {
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, .5);
}

.CardProduct .thumbnail {
    display: flex;
    position: relative;
    aspect-ratio: 1/1;
    overflow: hidden;
}

.CardProduct .thumbnail:hover img {
    transform: scale(1.05);
}

.CardProduct img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform .3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.CardProduct .cate-name {
    margin-top: -10px;
    font-size: 12px;
    font-weight: bold;
    color: var(--p-color);
    display: block;
}

.CardProduct .name {
    display: block;
    line-height: 1.2rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.CardProduct .price {
    display: inline-flex;
}

.CardProduct .newPrice {
    font-size: 1rem;
    font-weight: 600;
}

.CardProduct .beforeSale {
    font-size: 1rem;
    color: var(--gray-color);
    font-weight: 400;
    text-decoration: line-through;
}

.CardProduct .icon {
    float: right;
    background-color: var(--border-color);
    width: 30px;
    height: 30px;

    transform: translateY(-10%);
}

.CardProduct .icon svg {
    color: var(--p-color);
}

.CardProduct .quantity {
    padding: .5rem 0 1rem 0;
}

.CardProduct .quantity span {
    padding: 1rem;
    user-select: none;
}

.CardProduct .quantity button {
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
}

.CardProduct .quantity button.minus {
    background-color: var(--red-color);
}

.CardProduct .quantity button.plus {
    background-color: #46C389;
}

.CardProduct .quantity button:hover {
    background-color: var(--blk-color);
}

.CardProduct .quantity button svg {
    color: white;
}

.CardProduct .add-to-cart {
    width: 100%;
}
.ListTableCart td{
    padding: 1rem 1.5rem 1rem 0;
}
.ListTableCart td{
    border-bottom: 1px dashed var(--border-color);
}
.ListTableCart:last-child td{
    border-bottom: none
}

.ListTableCart svg {
    cursor: pointer;
}

.ListTableCart img {
    width: 85px;
    height: 85px;
    border-radius: 5px;
    object-fit: cover;
}

.ListTableCart .name {
    font-weight: 700;
}

.ListTableCart .price {
    color: var(--gray-color);
    font-size: .85rem;
    font-weight: 400;
}


.ListTableCart .quantity span {
    padding: 1rem;
    user-select: none;
}

.ListTableCart .quantity button {
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
}

.ListTableCart .quantity button svg {
    color: white;
}

.ListTableCart .quantity button.minus {
    background-color: var(--red-color);
}

.ListTableCart .quantity button.plus {
    background-color: #46C389;
}

.ListTableCart .quantity button:hover,
.ListTableCart button:hover {
    background-color: var(--blk-color);
}

@media screen and (max-width: 767px) {
    .ListTableCart img {
        width: 55px;
        height: 55px;
    }

}
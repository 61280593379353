.categories .cate-form {
    min-width: 350px;
}

.categories .cate-form .container-input .u-title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: .5rem;
    color: var(--a-blk-color);

    padding: 0;

    border-bottom: none;
}

.categories .cate-form .container-input .u-title span {
    color: var(--a-red-color);
}

.categories .cate-form .container-input {
    padding: 1.5rem;
}

.categories .cate-form input {
    background-color: var(--a-bg-color);
    padding: .5rem 1rem;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    margin-bottom: 1rem;
}
.categories .cate-form .container-input button{
    width: 100%;
}
.categories .cate-form .option {
    width: 100%;
    box-shadow: none;
}
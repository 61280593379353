.navAdmin {
    padding: 0 10px;
}

.navAdmin li a {
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    cursor: pointer;
    margin: 5px 0;
    user-select: none;
    border-radius: 5px;
}

.navAdmin li a.active {
    color: white;
    background-color: var(--a-p-color);
    box-shadow: 2px 2px 7px -3px rgba(40, 60, 76, 0.6);
    cursor: auto;
}

.navAdmin li a.active svg {
    color: white;
}
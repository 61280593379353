.ConfirmOrder .primary-color,
.ConfirmOrder .large-icon {
    font-weight: bold;
    color: var(--p-color);
}

.ConfirmOrder .large-icon {
    font-size: 15vw;
}

@media screen and (max-width: 767px) {
    .ConfirmOrder .large-icon {
        font-size: 55vw;
    }
}
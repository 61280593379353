nav.user ul {
    width: 100%;
    max-width: 1000px;
}

nav.user .list {
    /* color: var(--blk-color); */
    font-size: 1rem;
    font-weight: 600;
    margin: 0 40px;

    position: relative;
}

nav.user a::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background-color: var(--p-color);
    position: absolute;
    bottom: -1rem;
    left: 0;

    transition: all .3s ease;
}

nav.user .active::after,
nav.user a:hover:after {
    width: 100%;
}

nav.user .active {
    color: var(--p-color);
}

@media screen and (max-width: 767px) {
    nav.user a::after{
        display: none;
    }
}
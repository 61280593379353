header.admin {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid var(--a-border-color);
    padding: 0 1rem;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 99;
}

header.admin .logo {
    max-width: 200px;
}

header.admin .start {
    min-width: calc(225px);
    max-width: calc(225px);

}

header.admin .logo img {
    width: 100%;
    height: auto;
    max-width: 120px;
}

header.admin .end {
    width: 100%;
}

header.admin .userInfo {
    gap: 10px;
    padding-left: 1rem;
    margin-left: 1rem;
    border-left: 2px solid var(--a-border-color);
    cursor: pointer;
    position: relative;
    user-select: none;
}

header.admin .avatar {
    width: 30px;
    height: 30px;
}
header.admin .avatar img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 767px) {
    header.admin{
        padding: 0 .5rem;
        height: 60px;
    }
    header.admin .avatar{
        width: 50px;
        height: 50px;
    }
    header.admin .icon{
        width: 30px;
        height: 30px;
    }
    header.admin .userInfo {
        margin-left: 0;
    }
}
.update-user .container {

    padding: 2rem;
    border: 1px solid var(--a-border-color);
    border-radius: 10px;
    background-color: #fff;
}

.update-user .container .header {
    margin-bottom: 2rem;
}

.update-user .option {
    min-width: max-content;
}

.update-user .date {
    font-size: 14px;
    margin: 0 0 5px 0;
}

.update-user .container button:not(.primary-button) {
    background-color: #fff;
    border: 1px solid var(--a-border-color);
    height: 35px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: .5rem;
    cursor: pointer;
}

.update-user .container button:not(.primary-button) svg {
    color: var(--a-p-color);
    font-size: 1.2rem;
}

.update-user .container .icon {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    background-color: var(--a-bg-color);
    margin-right: 1rem;
}

.update-user .container .icon svg {
    font-size: 2.5rem;
    color: var(--a-blk-color);
}

.update-user .container .detail-content .title {
    margin-bottom: 5px;
}

.update-user .table {
    padding-bottom: 10px !important;
}

.update-user .table .image {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 1rem;
}

.update-user .table .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.update-user .long-text {
    resize: none;
}


@media screen and (max-width: 767px) {
    .update-user .container{
        width: 100%;
        box-sizing: border-box;
    }
    .update-user .container>div:first-child {
        flex-direction: column-reverse;
        
        gap: 1rem;
    }
    .update-user .container>div:first-child .actions span{
        display: none;
    }
    .update-user .container>div:first-child .actions .option{
        margin-left: 0;
    }
    .update-user .table{
     margin-right: 0 !important;
    }
}
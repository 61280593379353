.SidebarCategoryMobile {
    /* position: fixed; */
    /* top: 0; */
    /* left: -100%; */
    /* min-height: 100vh; */
    /* width: 350px; */
    /* max-width: 80vw; */
    background-color: white;
    box-sizing: border-box;
    /* padding: 2rem; */
    /* z-index: 999; */
    transition: left .5s cubic-bezier(0.165, 0.84, 0.44, 1);

    margin-top: 2rem;
}

.SidebarCategoryMobile.show {
    left: 0;
    transition: left .5s ease;
}

.SidebarCategoryMobile .u-title{
    color: var(--p-color);
}

.SidebarCategoryMobile .list{
    padding: 1rem 0;
}

.SidebarCategoryMobile .list a{
    color: var(--gray-color);
    padding: .5rem 0;
    display: block;
    border-bottom: 1px solid var(--border-color);
}

.SidebarCategoryMobile .list a.active{
    font-weight: 500;
    color: var(--blk-color);
}



.SidebarCategoryMobile .sidebar a{
    font-size: 500;
    padding: .35rem 0;
}
.SidebarCategoryMobile .sidebar ul{
    margin-bottom: 0;
}
.SidebarCategoryMobile .sidebar li {
    position: relative;
}

.SidebarCategoryMobile .sidebar li {
    width: 100%;
    display: block;
}

.SidebarCategoryMobile .sidebar li svg {
    color: var(--gray-color);
    position: absolute;
    top: .35rem;
    right: 0;
    z-index: 1;
    cursor: pointer;
    padding: 5px;
    box-sizing: content-box;
    user-select: none;
}
.SidebarCategoryMobile .sidebar li a{
    display: block;
    width: 100%;
    color: var(--gray-color);
}

.SidebarCategoryMobile .sidebar li .check {
    display: none;
}

.SidebarCategoryMobile .sidebar li .default {
    display: block;
}

.SidebarCategoryMobile .sidebar li.active .check {
    display: block;
}

.SidebarCategoryMobile .sidebar li.active .default {
    display: none;
}

.SidebarCategoryMobile .sidebar .sub-menu {
    height: 0;
    overflow: hidden;

    transition: all .3s ease;
}

.SidebarCategoryMobile .sidebar li.active > a {
    border-bottom: 1px solid var(--border-color);
}


.SidebarCategoryMobile .sidebar .sub-menu span{
    float: right;
    font-size: .85rem;
    color: var(--gray-color);
}
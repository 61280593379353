.contacts>.logo {
    margin: 2rem auto;
}

.contacts>div>div {
    padding: 3rem;
    margin-top: 3rem;
}

.contacts .left {
    background-color: var(--blk-color);
}

.contacts .left * {
    color: #fff;
}

.contacts .left>div {
    padding: 1rem 0;
}

.contacts .left svg {
    margin-right: 5px;
    margin-bottom: -3px;
}

.contacts .left span,
.contacts .left p {
    font-size: .85rem;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
}

.contacts .left p b {
    display: block;
    margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
    .contacts>div>div {
        padding: 1rem;
    }

    .contacts>.d-grid {
        gap: 0;
    }

    .contacts>div>div:last-child {
        margin-top: 0;
    }

}
.aSidebar-md,
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
}

.aSidebar-md {
    background-color: white;
    z-index: 999;
    width: 80vw;
    padding-top: 2rem;
    box-sizing: border-box;
    transition: left .3s ease;
}

.aSidebar-md .avatar {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
}

.aSidebar-md .username {
    font-weight: 700;
}

.aSidebar-md .logout {
    padding: 1rem;
    border-top: 1px solid var(--a-border-color);
}

.aSidebar-md .logout ul {
    margin: 0;
}

.aSidebar-md .userInfo {
    border: none;
    border-bottom: 1px solid var(--a-border-color);
    padding-bottom: 1.5rem;
    margin: 0 0 1.5rem 0;
}

@media screen and (max-width: 767px) {
    .aSidebar-md .userInfo{
        border-left: none !important;
        margin: none;
    }
}
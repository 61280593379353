.products .sort-by {
    border: 1px solid var(--border-color);
    border-radius: 5px;

    padding: .5rem .75rem;
    min-width: 140px;

    position: relative;
    cursor: pointer;
}

.products .sort-by .drop-down {
    display: none;
    position: absolute;
    top: 1rem;
    right: 0;

    width: max-content;
    min-width: 100%;

    z-index: 10;
    background-color: #fff;
    padding: .75rem 0;
    border-radius: 5px;
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, .25);

    user-select: none;
}

.products .sort-by .drop-down.show {
    display: block;
}

.products .sort-by .drop-down li {
    padding: .5rem .75rem;
    cursor: pointer;
}

.products .sort-by .drop-down li:hover {
    background-color: var(--light-gray-color);
}
.update-product .container {
    background-color: #fff;
    border-radius: 10px;

    padding: 3rem 2rem;
}

.update-product .container div {
    box-sizing: border-box;
}

.update-product .title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: .5rem;
    color: var(--a-blk-color);
}

.update-product .title span {
    color: var(--a-red-color);
}

.update-product textarea,
.update-product input {
    background-color: var(--a-bg-color);
    padding: .5rem 1rem;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    margin-bottom: 2rem;
}

.update-product .option {
    width: 100%;
    box-shadow: none;
}

.update-product .thumbnail,
.update-product .galleries {
    width: 100%;
    min-height: 260px;

    background-color: var(--a-bg-color);
    border-radius: 10px;

    margin-bottom: 25px;

    overflow: hidden;
    cursor: pointer;
}

.update-product .thumbnail svg,
.update-product .galleries svg {
    font-size: 5rem;
    color: var(--a-p-color);
}

.update-product .old-gallery img,
.update-product .thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.update-product .old-gallery {
    margin-bottom: 25px;
}

.update-product .old-list {
    position: relative;

    border: 1px solid var(--a-border-color);
    border-radius: 10px;

    box-sizing: border-box;
    padding: 10px;
}

.update-product .old-list,
.update-product .old-list .image {
    aspect-ratio: 1/1;
    overflow: hidden;

}

.update-product .old-list .image {
    border-radius: 5px;
}

.update-product .action {
    display: none;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, .35);
}

.update-product .old-list:hover .action {
    display: flex;
}

.update-product .upload-galleries .list {
    border-radius: 5px;
    border: 1px solid var(--a-border-color);
    padding: 10px;
    margin-bottom: .5rem;
}

.update-product .upload-galleries img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
}

.update-product .old-list .icon,
.update-product .upload-galleries .icon {
    background-color: var(--a-light-red-color);
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .3s ease;
}
.update-product .old-list .icon:hover,
.update-product .upload-galleries .icon:hover {
    transform: scale(.95);
}

.update-product .old-list .icon {
    width: 40px;
    height: 40px;
}

.update-product .old-list .icon svg,
.update-product .upload-galleries svg {
    color: var(--a-red-color);
}

@media screen and (max-width: 767px) {
    .update-product .container{
        padding: 2rem 1rem;
    }
}
.sidebarCart {
    position: fixed;
    top: 0;
    right: -100%;
    min-height: 100vh;
    width: 350px;
    max-width: 80vw;
    background-color: white;
    box-sizing: border-box;
    padding: 2rem;
    z-index: 99;
    transition: right .5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sidebarCart.show {
    right: 0;
    transition: right .5s ease;
    z-index: 999;
}

.sidebarCart .lists {
    padding-right: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 50vh;
}

.sidebarCart .lists::-webkit-scrollbar {
    width: 5px;
}

.sidebarCart .lists::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
}

.sidebarCart .lists:hover::-webkit-scrollbar-thumb {
    background-color: var(--border-color);
}

.sidebarCart .title {
    color: var(--p-color);
}

.sidebarCart img {
    width: 100%;
    width: 90px;
    max-width: 90px;
    aspect-ratio: 1/1;
    border-radius: 10px;
    overflow: hidden;
    object-fit: cover;
}

.sidebarCart .detail {
    position: relative;
}

.sidebarCart .name {
    font-size: .85rem;
}

.sidebarCart .amount {
    font-size: 1rem;
    font-weight: bold;
}

.sidebarCart .detail svg {
    font-size: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.sidebarCart .lists li {
    border-bottom: 1px dashed var(--border-color);
    padding: .75rem 0;
}

.sidebarCart .lists li:last-of-type {
    border-bottom: none;
}

.sidebarCart .total {
    padding: 1rem 0;
    border: 1px solid var(--border-color);
    border-left: none;
    border-right: none;
}

.sidebarCart .total span:last-child {
    font-size: 1.35rem;
}

.sidebarCart .buttons {
    margin-top: 1rem;
}

.sidebarCart .buttons .go-to-cart {
    background-color: var(--gray-color);
}

.sidebarCart .buttons button {
    width: 100%;
    margin-bottom: 10px;
}

.sidebarCart .quantity-mini-cart{
    display: flex;
    align-items: center;
    justify-items: start;
}
.sidebarCart .quantity-mini-cart span {
    padding: 1rem;
    user-select: none;
}

.sidebarCart .quantity-mini-cart button {
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
}

.sidebarCart .quantity-mini-cart button svg {
    color: white;
    position: relative;
}

.sidebarCart .quantity-mini-cart button.minus {
    background-color: var(--red-color);
}

.sidebarCart .quantity-mini-cart button.plus {
    background-color: #46C389;
}

.sidebarCart .quantity-mini-cart button:hover,
.sidebarCart .quantity-mini-cart button:hover {
    background-color: var(--blk-color);
}
@media screen and (max-width:767px) {
    .sidebarCart {
        padding: 1rem;
    }
}
.order-admin .dropdown{
    right: auto;
    left: 0;
}
.order-admin .ant-picker input::placeholder{
color: var(--a-blk-color);
}

.order-admin .table .dropdown{
    right: 0;
    left: auto;
}

@media screen and (max-width: 767px) {
    
.order-admin .table table{
    width: max-content;
}
}
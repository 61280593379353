.cart table {
    width: 100%;
    border-collapse: collapse;
}
.cart .u-table{
    width: 100%;
}

.cart table thead {
    text-align: left;

}

.cart table th {
    padding: .5rem 1rem .5rem 0;
    border-bottom: 2px solid var(--border-color);
}

.cart .right{
    min-width: 300px;
}
.cart .right .top-title{
    padding: .5rem 0;
    border-bottom: 2px solid var(--border-color);
}
.cart .right  > div{
    padding: 1.5rem 0;

    border-bottom: 1px dashed var(--border-color);
}

.cart .right  > div:last-of-type{
    border-bottom: none;
}

.cart .right button{
    font-weight: bold;
    color: #fff;

    padding: .75rem;
    border-radius: 5px;
    width: 100%;
    border: none;
    background-color: var(--red-color);

    cursor: pointer;

    transition: all .3s ease;
}

.cart .right  button:hover{
    transform: scale(.95);
    background-color: var(--blk-color);
}

@media screen and (max-width: 767px) {
    .cart .right{
        width: 100%;
    }
}
.card-highlight{
    background-color: white;
    border: 1px solid var(--a-border-color);
    padding: 1rem;
    border-radius: 10px;
}
.card-highlight span{
    display: block;
    font-size: 24px;
    font-weight: 700;
    margin: 5px 0;
}
.card-highlight .icon{
    width: 35px;
    height: 35px;
    border-radius: 5px;
}

@media screen and (max-width: 767px) {
    .container-card-highlight{
        max-width: calc(100vw - 20px);
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 1.2rem;
    }
    .container-card-highlight > div{
        width: max-content;
    }
    .card-highlight{
        min-width: 240px;
    }
}
.mini-cart {
    width: max-content;
    min-width: 110px;
    height: 40px;
    background-color: var(--border-color);

    position: fixed;
    bottom: 0;
    right: 3vw;
    box-sizing: content-box;
    padding: .35rem .5rem;
    border-radius: 10px 10px 0 0;

    cursor: pointer;
}

.mini-cart svg {
    font-size: 2rem;
    color: var(--blk-color);
}

.mini-cart .count {
    color: white;
    font-size: 8px;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    right: -5px;
    background-color: var(--red-color);
}

.mini-cart .total {
    font-size: 1.25rem;
    font-weight: bold;
    padding-left: 5px;
}

@media screen and (max-width: 767px) {
    .mini-cart {
        /* bottom: 55px; */
        z-index: 10;
    }
}
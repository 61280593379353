.review img {
    width: 100%;
}

.review .box {
    text-align: center;

    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 1.25rem;
}

.review .box .avatar {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    margin: 0 auto .5rem auto;
}

.review .box .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.l-review .job,
.review .box .job {
    font-size: .85rem;
    color: var(--gray-color);
}

.l-review svg,
.review .box svg {
    font-size: 1.5rem;
    color: var(--p-color);
    padding: .5rem 0;
}

.l-review {
    border-radius: 10px;
    height: 100%;
    min-height: 300px;
    max-height: 300px;
}

.l-review .image {
    min-width: 280px;
    max-width: 280px;
    overflow: hidden;
}

.l-review .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.l-review .detail {
    padding: 2.5rem;
    border: 1px solid var(--border-color);
    border-left: none;
}

.l-review .name {
    padding-top: 1.2rem;
}

.l-review svg {
    font-size: 2rem;
}

.l-review .text,
.review .box .text {
    font-size: .85rem;
}

.l-review .text {
    max-width: 300px;
}

@media screen and (max-width: 767px) {
    .l-review {
        max-height: max-content;
    }

    .l-review .image {
        max-width: 100%;
    }
}
.dealOfDay {
    padding: 1.2rem;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    margin-top: 1.5rem;
}

.dealOfDay .thumbnail {
    aspect-ratio: 1/1;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.dealOfDay .thumbnail img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
    overflow: hidden;
}

.dealOfDay .name{
    font-size: 1.25rem;
    font-weight: bold;
    /* color: var(--blk-color); */

    padding-bottom: 5px;
}

.dealOfDay .desc *{
    color: var(--gray-color);
    font-weight: 400;
}
.dealOfDay .desc > span{
    font-size: .85rem;
}
.dealOfDay .price{
    padding: .5rem 0;
}
.dealOfDay .beforeSale,
.dealOfDay .newPrice{
    font-weight: bold;
    color: var(--p-color);
    font-size: 1.5rem;
}
.dealOfDay .newPrice{
    font-weight: bold;
    color: var(--p-color);
}

.dealOfDay .beforeSale{
    font-weight: 300;
    text-decoration: line-through;
    color: var(--gray-color);
}

.counter div{
    width: 50px;
    height: 45px;

    text-align: center;
    border-radius: 5px;
    background-color: var(--light-gray-color);

    margin-top: 1rem;
}
.counter div span, .counter div p{
    color: #000;
}
.counter div p{
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: -5px;
}

.counter div span{
    font-size: 10px;
    font-weight: 300;
}

@media screen and (max-width: 767px) {
    .dealOfDay{
        width: 100%;
    }
}
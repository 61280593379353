.UpdateDealOfDay .container {
    background-color: #fff;
    border-radius: 10px;

    padding: 3rem 2rem;
}

.UpdateDealOfDay .container div {
    box-sizing: border-box;
}

.UpdateDealOfDay .title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: .5rem;
    color: var(--a-blk-color);
}

.UpdateDealOfDay .title span {
    color: var(--a-red-color);
}

.UpdateDealOfDay textarea,
.UpdateDealOfDay input {
    background-color: var(--a-bg-color);
    padding: .5rem 1rem;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    margin-bottom: 2rem;
}

.UpdateDealOfDay .option {
    width: 100%;
    box-shadow: none;
}

.UpdateDealOfDay .thumbnail,
.UpdateDealOfDay .galleries {
    width: 100%;
    min-height: 260px;

    background-color: var(--a-bg-color);
    border-radius: 10px;

    margin-bottom: 25px;

    overflow: hidden;
    cursor: pointer;
}

.UpdateDealOfDay .thumbnail svg,
.UpdateDealOfDay .galleries svg {
    font-size: 5rem;
    color: var(--a-p-color);
}

.UpdateDealOfDay .old-gallery img,
.UpdateDealOfDay .thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.UpdateDealOfDay .old-gallery {
    margin-bottom: 25px;
}

.UpdateDealOfDay .old-list {
    position: relative;

    border: 1px solid var(--a-border-color);
    border-radius: 10px;

    box-sizing: border-box;
    padding: 10px;
}

.UpdateDealOfDay .old-list,
.UpdateDealOfDay .old-list .image {
    aspect-ratio: 1/1;
    overflow: hidden;

}

.UpdateDealOfDay .old-list .image {
    border-radius: 5px;
}

.UpdateDealOfDay .old-list .icon {
    background-color: var(--a-light-red-color);
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .3s ease;
}

.UpdateDealOfDay .old-list .icon:hover {
    transform: scale(.95);
}

.UpdateDealOfDay .old-list .icon {
    width: 40px;
    height: 40px;
}

.UpdateDealOfDay .old-list .icon svg {
    color: var(--a-red-color);
}

.UpdateDealOfDay .input * {
    min-width: 100% !important;
}

.UpdateDealOfDay .datepick input::placeholder{
    color: #000 !important;
}
@media screen and (max-width: 767px) {
    .UpdateDealOfDay .container {
        padding: 2rem 1rem;
    }
}

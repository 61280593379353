.bestSeller{
    min-width: 350px;
    width: 350px;
}
.bestSeller img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bestSeller .list .img {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
}

.bestSeller .list .name {
    font-weight: 600;
}

.bestSeller .list .order {
    font-size: 12px;
    color: #a7afb5;
}
.container-sidebar .sidebar {
    padding: 2rem 1.5rem;
    border-radius: 10px;
    border: 2px solid var(--border-color);

    margin-bottom: 1rem;
}

.container-sidebar .sidebar a{
    font-size: 500;
    padding: .35rem 0;
}
.container-sidebar .sidebar .title {
    font-size: 1rem;
    font-weight: bold;
    color: var(--blk-color);
}
.container-sidebar .sidebar ul{
    margin-bottom: 0;
}
.container-sidebar .sidebar li {
    position: relative;
}

.container-sidebar .sidebar li {
    width: 100%;
    display: block;
}

.container-sidebar .sidebar li svg {
    color: var(--gray-color);
    position: absolute;
    top: .35rem;
    right: 0;
    z-index: 1;
    cursor: pointer;
    padding: 5px;
    box-sizing: content-box;
    user-select: none;
}
.container-sidebar .sidebar li a{
    display: block;
    width: 100%;
    /* color: var(--gray-color); */
}

.container-sidebar .sidebar li .check {
    display: none;
}

.container-sidebar .sidebar li .default {
    display: block;
}

.container-sidebar .sidebar li.active .check {
    display: block;
}

.container-sidebar .sidebar li.active .default {
    display: none;
}

.container-sidebar .sidebar .sub-menu {
    height: 0;
    overflow: hidden;

    transition: all .3s ease;
}

.container-sidebar .sidebar li.active > a {
    border-bottom: 1px solid var(--border-color);
}


.container-sidebar .sidebar .sub-menu span{
    float: right;
    font-size: .85rem;
    color: var(--gray-color);
}
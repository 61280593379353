.payment .card {
    font-weight: bold;

    border: 1px solid var(--border-color);
    padding: 1.5rem;
    border-radius: 10px;
}
.payment .card img{
    max-width: 150px;
    padding-bottom: 1rem;
}